import React, { Fragment, useRef } from 'react';
import {  graphql, Link , useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import _map from 'lodash/map';
//import Slider1 from '../components/Slider/slider';
// import Swiper core and required components
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Keyboard,  Navigation  } from 'swiper';

import styleSwiper from 'swiper/swiper.scss';
import styleNavigation from 'swiper/components/navigation/navigation.scss';
console.log({ styleSwiper,styleNavigation  })

import {  ChevronCompactLeft, ChevronCompactRight, ArrowLeftShort, ArrowRightShort} from '@styled-icons/bootstrap';
import { PageWrapper, PageInner, PageTitle, AppsIcon, ProjectsListLink, SeeMoreLink3, Spacer, Grid2Col, ProjetLink ,  ArrowLeftLink, ArrowRightLink, ArrowLeftIcon, ArrowRightIcon } from '../components/Elements';
import { colors, mq, font } from '../consts/style';
import { projetTypes } from '../types/propTypes';
import SEO from '../components/SEO';

// install Swiper components
SwiperCore.use([Navigation, Keyboard]);

const PageTitleNoMargin =   styled(PageTitle)`
margin-bottom:0;

`


const ArrowLeftLinkNav = styled(ArrowLeftLink)`
  position:absolute;
 /* bottom:4.5rem;*/
/*  left: 4.2rem;*/
bottom:50%;
left:2rem;
  z-index:1;
  cursor:pointer;
  &.swiper-button-disabled{
    opacity:.1;
  }
`;

const ArrowRightLinkNav = styled(ArrowRightLink)`
  position:absolute;
/*  bottom:4.5rem;*/
  /*left: calc(4.2rem + 50px + 1.1rem);*/
  bottom:50%;
right:2rem;
  z-index:1;
  cursor:pointer;
   &.swiper-button-disabled{
    opacity:.1;
  }
`;


const DiaporamaFullWidth = styled.div`
  position: relative;
  margin-right: calc(-50vw + 50%);
  z-index: 0;
  .swiper-slide .gatsby-image-wrapper  {
    filter:grayscale(1)!important;
     transition:all .4s ease;
  }
  .swiper-slide-active .gatsby-image-wrapper {
    filter:none!important;
    transition:all .4s ease;
  }

  .swiper-slide {
    opacity:0.3;
     transition:opacity .4s ease;
  }
   .swiper-slide-active {
    opacity:1;
    transition:opacity .4s ease;
   }

  .swiper-button-next{  
    display:none;
  }

   .swiper-button-prev {
    display:none;
   }

`;

const Slide = styled.div`
  position:relative;
  max-width:80rem;
  background:white;
  margin-right:10rem!important;
  
`;

const ImgDiaporama =styled(Img)`
  
  /* max-height:60rem;*/
`

const LieuDate = styled.div`
  ${font.h1};

  font-size:3rem!important;
  letter-spacing:.75px;
  color: ${colors.greyLight};
   margin:1.5rem 0;
  
  ${mq.tabletSmall_up`
  margin: 2.4rem 0 0 ;
  margin-left:29px;
  float:right;
`} 

`;

const HeaderProjet = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  .titre_lieu {
    display:block; 
    margin:0;
    ${mq.tabletSmall_up`
    display:flex;
    margin: 2.4rem 0;
    align-items: flex-end;
  `} 
}

`;

const GridText = styled.div`
  ${mq.tablet_up`
    display:grid;
    grid-template-columns: 2fr 1fr;
    grid-gap:10rem;
 `}

`;

const ProjetSubTitle = styled.h3`
  ${font.styleProjetSubTitle};
  margin-bottom:2rem;
`;

const Subtitle = styled.p`
  margin-bottom:3.4rem;
  margin-top:-.5rem;
  ${mq.tabletSmall_up`
  margin-top:-1rem;
`} 
`;




const Projet = ({ data, pageContext }) => {
  const { titre, seoMetaTags, programme, construction, maitriseOuvrage, lieu, annE, surface, typeProjet, typeMission, diaporamaPhotos, diaporamaPlans} = data.projet;
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  
  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <PageWrapper>

        <PageInner>
          <HeaderProjet>
            <div className="titre_lieu">
              <PageTitleNoMargin>{titre} </PageTitleNoMargin>
              <LieuDate> {lieu}, {annE}</LieuDate>
            </div>
           
          
            <ProjectsListLink to="/projets" title="Retour à la galerie de projets">
              <span>Retour à la galerie de projets</span> 
              <AppsIcon title="Retour à la galerie de projets" />
          </ProjectsListLink>
          </HeaderProjet>
           <Subtitle>{typeProjet.typeProjet} / Maîtrise d'ouvrage {maitriseOuvrage} / {typeMission.typeMission} / Surface SHON: {surface} m²
            </Subtitle>



<DiaporamaFullWidth>
 <Swiper
    navigation
    spaceBetween={35}
    slidesPerView={1.2}
    keyboard
    grabCursor
    freeMode
    onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }} 
  
    breakpoints={{   
      // when window width is >= 768px
      1200: {
       /* width: 1024,*/
        slidesPerView: 1.4,
      },
    }}
    >
     {
        _map(diaporamaPhotos, ( image) => (      
          <SwiperSlide key={image.originalId}>
            <Img fluid={image.fluid} />
          </SwiperSlide>
        )
        )
        }
        {

        _map(diaporamaPlans, ( image) => (      
          <SwiperSlide key={image.originalId} style={{"border":"1px solid #707070"}}>
            <Img fluid={image.fluid} />
          </SwiperSlide>
        )
        )
      }
      <SwiperSlide key="blank">      
      </SwiperSlide>

       
            <ArrowLeftLinkNav ref={prevRef} >
              <ArrowLeftIcon title="Précédent"/>
            </ArrowLeftLinkNav>
             <ArrowRightLinkNav ref={nextRef} >
              <ArrowRightIcon title="Suivant"/>
            </ArrowRightLinkNav>
    </Swiper>
 </DiaporamaFullWidth>






        </PageInner>
 {/*
        <Diaporama>
        <Slider1 usage="projet">
        {

          _map(diaporamaPhotos, ( image) => (      
            <Slide key={image.originalId}>
              <ImgDiaporama fluid={image.fluid} style={{ margin: '0 5rem 0 0' }}/>
            </Slide>
            )
          )
        }
        </Slider1>
        </Diaporama>
  */}  
        <Spacer/>
        <PageInner>
          <GridText>
            <div>
              <ProjetSubTitle>programme</ProjetSubTitle>
              <div dangerouslySetInnerHTML={{ __html: programme }} />
               <Spacer/>

            </div>
            <div> 
              <ProjetSubTitle>construction / performance</ProjetSubTitle>
              <div dangerouslySetInnerHTML={{ __html: construction }} />
               <Spacer/>
            </div>
          </GridText>
        </PageInner>
 {/*
        <PageInner>
      <DiaporamaFullWidth>
       <Swiper
          navigation
          spaceBetween={35}
          slidesPerView={1.2}
          keyboard
          grabCursor
          freeMode
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.update();
          }} 
          breakpoints={{   
            // when window width is >= 768px
            1200: {
            
              slidesPerView: 1.4,
            },
          }}
          >
           {
              _map(diaporamaPlans, ( image) => (      
                <SwiperSlide key={image.originalId} style={{"border":"1px solid #707070"}}>
                  <Img fluid={image.fluid} />
                </SwiperSlide>
              )
              )
            }
            <SwiperSlide key="blank">      
            </SwiperSlide>
            <ArrowLeftLinkNav ref={prevRef} >
              <ArrowLeftIcon title="Précédent"/>
            </ArrowLeftLinkNav>
             <ArrowRightLinkNav ref={nextRef} >
              <ArrowRightIcon title="Suivant"/>
            </ArrowRightLinkNav>
          </Swiper>
       </DiaporamaFullWidth>
        <Spacer/>
 </PageInner>
          */}

        <PageInner>
          <div style={{"textAlign":"center"}}>
            <SeeMoreLink3 to="/projets" title="Retour à la galerie de projets" icon="menu_projet">
              <AppsIcon title="Retour à la galerie de projets" />
              <span>Retour à la galerie de projets</span>  
            </SeeMoreLink3>
          </div>
          
          <Grid2Col >
          <div>

            {pageContext.previous && (
             <ProjetLink>
              <Link to={`/projets/${pageContext.previous.slug}`}   rel="prev" >
                  <div className="nav-helper">
                    <ChevronCompactLeft size="18" title="Précédent"/> 
                    <span>Projet précédent</span> 
                  </div>
                  <Img fluid={pageContext.previous.imagePrincipale.fluid} alt={pageContext.previous.titre}/>
                  <h3 className="title">{pageContext.previous.titre}</h3>
                  <span className="description">
                      {pageContext.previous.typeProjet.typeProjet} / {pageContext.previous.surface} m² / {pageContext.previous.annE} </span>
                </Link>
              </ProjetLink>
             
            )}
            </div>
            <div>
              {pageContext.next && (
                <ProjetLink>
                <Link to={`/projets/${pageContext.next.slug}`}   rel="next" style={{"textAlign":"right"}}>
                  <div className="nav-helper"  style={{"justifyContent":"flex-end"}}>
                    <span>Projet suivant</span>
                    <ChevronCompactRight size="18" title="Suivant"/>
                  </div>
                  <Img fluid={pageContext.next.imagePrincipale.fluid} alt={pageContext.next.titre}/>
                  <h3 className="title">{pageContext.next.titre}</h3>
                  <span className="description">
                      {pageContext.next.typeProjet.typeProjet} / {pageContext.next.surface} m² / {pageContext.next.annE} </span>
                </Link>
                </ProjetLink>
              )}
            </div>
        </Grid2Col>
        <Spacer/>

        </PageInner>
      </PageWrapper>
    </Fragment>
  );
};

export const projectQuery = graphql`
  query($slug: String!) {
    projet: datoCmsProjet(slug: { eq: $slug }) {
      titre
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      programme
      construction
      annE
      surface
      id
      typeProjet  {
        typeProjet
      }
      typeMission  {
        typeMission
      }
      maitriseOuvrage
      lieu
      slug
      diaporamaPhotos {
        originalId
        fluid(maxHeight:680, imgixParams: {fit: "crop", w: "1200", h: "720", fm: "jpg", auto: "compress" }) {
        ...GatsbyDatoCmsFluid
        }
      }
      diaporamaPlans {
        originalId
        fluid(maxHeight:680, imgixParams: {fit: "crop", w: "1200", h: "720", fm: "jpg" ,  auto: "compress" }) {
        ...GatsbyDatoCmsFluid
        }
      }
    }
  }
`;

Projet.propTypes = projetTypes;

export default Projet;
